import React from "react";
import * as FiIcons from "react-icons/fi";
import * as IoIcons from "react-icons/io";
import * as BiIcons from "react-icons/bi";
import * as PiIcons from "react-icons/pi";
import { LuWalletCards } from "react-icons/lu";
import { Links } from "constants/Links";
import { Image } from "react-native-web";
import { COLORS } from "constants/Colors";

export const SidebarData = [
	{
		title: "Dashboard",
		path: Links.Dashboard,
		icon: <FiIcons.FiHome size={22} />
	},
	{
		title: "Donations",
		path: Links.Donations,
		icon: <FiIcons.FiDollarSign size={22} />,
		detailedNav: [
			{
				title: "Donor Details",
				path: Links.DonorDetails,
				icon: <FiIcons.FiCalendar size={22} />,
				cName: "sub-nav"
			}
		]
	},
	{
		title: "Champions",
		path: Links.Fundraising,
		icon: <FiIcons.FiHeart size={22} />,
		detailedNav: [
			{
				title: "Fundraiser Details",
				path: Links.FundraisingDetail,
				icon: <FiIcons.FiCalendar size={22} />,
				cName: "sub-nav"
			}
		]
	},
	{
		title: "Custom Links",
		path: Links.CustomLinks,
		icon: <PiIcons.PiQrCode size={25} />,
		detailedNav: [
			{
				title: "Custom Link Details",
				path: Links.CustomLinkDetails,
				icon: <IoIcons.IoMdArrowDropright />,
				cName: "sub-nav"
			}
		]
	},
	{
		title: "Events",
		path: Links.Events,
		icon: <FiIcons.FiCalendar size={22} />,
		detailedNav: [
			{
				title: "Event Detail",
				path: Links.EventDetail,
				icon: <IoIcons.IoMdArrowDropright />,
				cName: "sub-nav"
			}
		]
	},
	{
		title: "Team",
		path: Links.Team,
		icon: <FiIcons.FiUsers size={22} />
	},
	{
		title: "General Settings",
		path: Links.Settings,
		icon: <FiIcons.FiSettings size={22} />
	},
	{
		title: "Verification",
		path: Links.Verification,
		icon: (
			<Image
				source={require("assets/verification.png")}
				style={{ width: 22, height: 22, tintColor: COLORS.primary.lightBlue }}
			/>
		)
	}
	// {
	//   title: 'Opportunities',
	//   path: Links.Opportunities,
	//   icon: <FaIcons.FaChartLine />
	// },
	// {
	//   title: 'Reports',
	//   icon: <IoIcons.IoIosPaper />,

	//   iconClosed: <RiIcons.RiArrowDownSFill />,
	//   iconOpened: <RiIcons.RiArrowUpSFill />,

	//   subNav: [

	//     {
	//       title: 'Donations',
	//       path: Links.InventoryLocations,
	//       icon: <IoIcons.IoMdArrowDropright />
	//     },
	//   ]
	// },
	// {
	//   title: 'Support',
	//   path: Links.Support,
	//   icon: <IoIcons.IoMdHelpCircle />
	// }
];

export const BusinessSidebarData = [
	{
		title: "Dashboard",
		path: Links.BusinessDashboard,
		icon: <FiIcons.FiHome size={22} />
	},
	{
		title: "Rayze Wallet",
		path: Links.RayzeWallet,
		icon: <LuWalletCards size={22} />
	},
	{
		title: "Donations",
		path: Links.BusinessDonations,
		icon: <FiIcons.FiDollarSign size={22} />
	},
	// {
	//   title: "Volunteers",
	//   path: Links.BusinessVolunteers,
	//   icon: <FiIcons.FiUsers size={22} />,
	// },
	{
		title: "Campaigns",
		path: Links.BusinessCampaigns,
		icon: <FiIcons.FiCalendar size={22} />,
		detailedNav: [
			{
				title: "Campaign Details",
				path: Links.BusinessCampaignDetails,
				icon: <FiIcons.FiCalendar size={22} />,
				cName: "sub-nav"
			}
		]
	},
	{
		title: "Nonprofits",
		path: Links.Nonprofits,
		icon: <BiIcons.BiDonateHeart size={22} />,
		detailedNav: [
			{
				title: "Nonprofit Detail",
				path: Links.NonprofitDetail,
				icon: <IoIcons.IoMdArrowDropright />,
				cName: "sub-nav"
			}
		]
	},
	{
		title: "Employees",
		path: Links.BusinessEmployees,
		icon: <FiIcons.FiUser size={22} />,
		detailedNav: [
			{
				title: "Employee Details",
				path: Links.BusinessEmployeeDetail,
				icon: <FiIcons.FiUser size={22} />
			}
		]
	},
	{
		title: "General Settings",
		path: Links.GeneralSettings,
		icon: <FiIcons.FiSettings size={22} />
	},
	{
		title: "Verification",
		path: Links.Verification,
		icon: (
			<Image
				source={require("assets/verification.png")}
				style={{ width: 22, height: 22, tintColor: COLORS.primary.lightBlue }}
			/>
		)
	}
];
